<template>
  <NuxtLayout>
    <Container>
      <div class="mx-auto max-w-screen-lg py-52">
        <h2 class="text-3xl font-semibold">
          {{ $t(`error.${error.statusCode}.title`) }}
        </h2>
        <div class="my-8">
          {{ $t(`error.${error.statusCode}.text`) }}
        </div>
        <AppButton size="sm" @click="clearError({ redirect: '/' })">
          {{ $t('general.backToFrontPage') }}
        </AppButton>
        <div class="relative my-4 overflow-x-scroll">
          <div class="bg-red-700 px-4 py-2 text-white">
            {{ error.message }}
          </div>
          <pre class="pt-4" v-html="error.stack" />
        </div>
      </div>
    </Container>
  </NuxtLayout>
</template>

<script setup lang="ts">
const props = defineProps<{
  error: {
    statusCode: number
    message: string
    stack: any
  }
}>()

const runtimeConfig = useRuntimeConfig()
const { getWishlistProducts } = useWishlist()
const { refreshCart } = useCart()
const { t } = useI18n()

const { init } = useInit()

// Shopware prefix
provide('urlPrefix', runtimeConfig.public.SHOP_PREFIX)

// notifications won't work without initializing them first
useNotifications()

// Use SSR-safe IDs for Headless UI
provideHeadlessUseId(() => useId())

useHead({
  titleTemplate: (title) =>
    title
      ? `${process.dev ? '🚧 ' : ''}${title} - JP Rosselet Cosmetics`
      : `${process.dev ? '🚧 ' : ''}JP Rosselet Cosmetics`,
})

await init()

onMounted(() => {
  if (import.meta.client) {
    getWishlistProducts()
    refreshCart()
  }
})

useHead({
  title: t(`error.${props.error.statusCode}.title`),
})
</script>
