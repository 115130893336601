<template>
  <Component
    :is="items.length > 0 ? 'button' : 'div'"
    class="flex w-full items-center justify-between py-3 outline-none focus:outline-none"
    @click="isOpen = !isOpen"
  >
    <Component :is="'icon-' + icon" v-if="icon" class="mr-4 w-4 stroke-2" />
    <span class="w-full text-left">
      <slot />
    </span>
    <template v-if="items.length > 0">
      <IconAngleRight class="w-4" />

      <ClientOnly>
        <Teleport to="body">
          <Transition name="slide-right-full">
            <MobileMenuNavDropdown
              v-show="isOpen"
              :items="items"
              :depth="depth"
              @close="isOpen = false"
            >
              <slot />
            </MobileMenuNavDropdown>
          </Transition>
        </Teleport>
      </ClientOnly>
    </template>
  </Component>
</template>

<script setup lang="ts">
withDefaults(
  defineProps<{
    icon?: string
    items?: any[]
    depth?: number
  }>(),
  {
    items: () => [],
  }
)

const isOpen = ref(false)
</script>
